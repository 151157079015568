import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
import { UserDataService } from '../../authentication/user-data.service';
import { GridQueriesService } from '../utils/grid-queries.services';

@Injectable({
  providedIn: 'root'
})
export class RomaneosDeContratosAFijarServiceService {
  constructor(
    private readonly http: ApiService,
    public readonly userDataService: UserDataService,
    private readonly gridService: GridQueriesService,
  ) { }

  /**
   * Service for Romaneos de Contratos a Fijar
   * @param page page
   * @param count count
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   */
  listRomaneosDeContratosAFijar(
    page: number,
    count: number,
    orderBy: string,
    order: string,
    filters: any
  ) {
    let params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('pagina', page.toString())
      .set('cantidad', count.toString())
      .set('ordenadoPor', orderBy)
      .set('orden', order);

    params = this.http.addFilterParams(filters, params);

    return this.http.get('contratos/romaneos-de-contratos-a-fijar', params);
  }

  /**
   * Gets column list for romaneos grid based on the active user's cuit
   */
  listRomaneosDeContratosAFijarColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/romaneos-de-contratos-a-fijar', params);
  }
}
