import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
import { UserDataService } from '../../authentication/user-data.service';
import { GridQueriesService } from '../utils/grid-queries.services';

@Injectable({
  providedIn: 'root'
})
export class ContractsToSetService {
  constructor(
    private readonly http: ApiService,
    public readonly userDataService: UserDataService,
    private readonly gridService: GridQueriesService,
  ) {
  }

  /**
   * Service for Contracts to set List
   */
  listContractsToSet(
    page: number,
    count: number,
    from: string,
    to: string,
    orderBy: string,
    order: string,
    filters: any
  ) {
    return this.gridService.getGridData('contratos/contratos-a-fijar', {
      page, count, from, to, orderBy, order, filters
    });
  }

  /**
   * Gets column list for unloads grid based on the active user's cuit
   */
  listContractsToSetColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/contratos-a-fijar', params);
  }
}
