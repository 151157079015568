import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
import { UserDataService } from '../../authentication/user-data.service';
import { GridQueriesService } from '../utils/grid-queries.services';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  constructor(
    private readonly http: ApiService,
    public readonly userDataService: UserDataService,
    private readonly gridService: GridQueriesService,
  ) { }

  /**
   * Service for Applications List
   * @param page page
   * @param count count
   * @param from from
   * @param to to
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   */
  listApplications(
    page: number,
    count: number,
    from: string,
    to: string,
    orderBy: string,
    order: string,
    filters: any
  ) {
    return this.gridService.getGridData('contratos/aplicaciones', {
      page,
      count,
      from,
      to,
      orderBy,
      order,
      filters
    });
  }

  /**
   * Service for Applications Totals
   * @param from from
   * @param to to
   * @param filters filters
   */
  listApplicationsTotals(from: string, to: string, filters: any) {
    return this.gridService.getTotals('contratos/aplicaciones-totales', {
      from, to, filters
    });
  }

  /**
   * Gets column list for unloads grid based on the active user's cuit
   */
  listAplicationsColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/aplicaciones', params);
  }
}
