import { HttpParams } from '@angular/common/http';
import { ApiService } from '../../http/api.service';
import { Injectable } from '@angular/core';
import { UserDataService } from '../../authentication/user-data.service';
import { exportXLS } from 'src/app/_shared/helpers/fileHelper';
import { GoogeTagService } from '../google-tag-manager/googe-tag-manager.service';
import { catchError, map, of } from 'rxjs';

interface GridParamsOptions {
  page: number;
  count: number;
  from?: string;
  to?: string;
  orderBy: string;
  order: string;
  filters: any;
}

interface GridXLSParams {
  from?: string;
  to?: string;
  orderBy: string;
  order: string;
  filters: any;
  columns: Array<string>;
  module: string;
}

interface TotalsParams {
  from: string;
  to: string;
  filters: any;
}

@Injectable({
  providedIn: 'root'
})
export class GridQueriesService {
  constructor(
    private readonly http: ApiService,
    private readonly userDataService: UserDataService,
    private readonly googleTagService: GoogeTagService,
  ) { }

  getGridData(query: string, gridParams: GridParamsOptions) {
    const {
      page, count, from, to, orderBy, order, filters
    } = gridParams;

    let params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('pagina', page.toString())
      .set('cantidad', count.toString())
      .set('desde', from || '')
      .set('hasta', to || '')
      .set('ordenadoPor', orderBy)
      .set('orden', order);

    params = this.http.addFilterParams(filters, params);

    return this.http.get(query, params);
  }

  downloadXls(query: string, xlsParams: GridXLSParams) {
    const {
      from, to, orderBy, order, filters, columns, module
    } = xlsParams;

    let params = new HttpParams()
      .set('pagina', '1')
      .set('cantidad', '1')
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('desde', from || '')
      .set('hasta', to || '')
      .set('ordenadoPor', orderBy)
      .set('orden', order);
    params = this.http.addFilterParams(filters, params);

    const xlsColumns = columns;
    params = this.http.addXlsParams(xlsColumns, params);

    return this.http.download(query, params).pipe(
      map((res: any) => {
        exportXLS(res, module).then((filename) => {
          // Enviar evento exitoso descarga .xlsx a Tag Manager.
          this.googleTagService.sendDownloadEvent({
            modulo: module,
            documento: filename,
            extension: '.xlsx',
            seccion: query
          });
        });
      }),
      catchError((err) => {
        // Enviar evento de error de descarga .xlsx a Tag Manager.
        console.error(err);
        this.googleTagService.sendErrorEvent({ accion: 'descarga' }, undefined, {
          modulo: module,
          extension: '.xlsx',
          seccion: query,
        });
        return of(err);
      })
    );
  }

  /**
   * Get totals
   * @param query url to get data
   * @param totalsParams params
  */
  getTotals(query: string, totalsParams: TotalsParams) {
    const { from, to, filters } = totalsParams;

    let params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('desde', from || '')
      .set('hasta', to || '');

    params = this.http.addFilterParams(filters, params);

    return this.http.get(query, params);
  }
}
