import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
import { UserDataService } from '../../authentication/user-data.service';
import { GridQueriesService } from '../utils/grid-queries.services';


@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  constructor(
    private readonly http: ApiService,
    public readonly userDataService: UserDataService,
    private readonly gridQueriesService: GridQueriesService
  ) { }

  /**
   * Service for Payments List
   * @param page page
   * @param count count
   * @param from from
   * @param to to
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   */
  listPayments(
    page: number,
    count: number,
    from: string,
    to: string,
    orderBy: string,
    order: string,
    filters: any
  ) {
    return this.gridQueriesService.getGridData('pagos/ultimos-pagos/', {
      page,
      count,
      from,
      to,
      orderBy,
      order,
      filters
    });
  }

  /**
   * Returns pay order details.
   * @param payOrder String that identifies pay order.
   */
  getOrderGeneralInfo(payOrder: string) {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get(`pagos/${payOrder}`, params);
  }

  /**
   * Returns pay order proofs.
   * @param payOrder String that identifies pays order.
   * @param page page
   * @param count count
   */
  getOrderProofs(payOrder: string, page: number, count: number) {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('pagina', page.toString())
      .set('cantidad', count.toString());
    return this.http.get(`pagos/${payOrder}/comprobantes`, params);
  }

  /**
   * Returns pay order instruments.
   * @param payOrder String that identifies pays order.
   * @param page page
   * @param count count
   */
  getOrderInstruments(payOrder: string, page: number, count: number) {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('pagina', page.toString())
      .set('cantidad', count.toString());
    return this.http.get(`pagos/${payOrder}/instrumentos`, params);
  }

  /**
   * Returns pay order retentions.
   * @param payOrder String that identifies pays order.
   */
  downloadRetentionsPDF(payOrder: string) {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('exportarPdf', 'true');

    return this.http.downloadAsString(`pagos/${payOrder}/retenciones`, params);
  }

  /**
   * Returns pay order PDF.
   * @param payOrder String that identifies pay order.
   */
  downloadOrdenDePagoPDF(payOrder: string) {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('exportarPdf', 'true');

    return this.http.downloadAsString(`pagos/${payOrder}`, params);
  }

  /**
   * Returns pay order retentions.
   * @param payOrder String that identifies pays order.
   */
  getOrderRetentions(payOrder: string) {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get(`pagos/${payOrder}/retenciones`, params);
  }


  /**
   * Gets column list for sales grid based on the active user's cuit
   */
  listSalesColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/ventas', params);
  }

  /**
   * Gets column list for payments' grid based on the active user's cuit
   */
  listPaymentsColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/cobros', params);
  }

  /**
   * Gets column list for retentions' grid based on the active user's cuit
   */
  listRetentionsColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/retenciones', params);
  }
}
