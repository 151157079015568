import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
import { UserDataService } from '../../authentication/user-data.service';
import { GridQueriesService } from '../utils/grid-queries.services';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {
  constructor(
    private readonly http: ApiService,
    private readonly userDataService: UserDataService,
    private readonly gridQueriesService: GridQueriesService
  ) {
  }

  /**
   * Service for Contracts List
   */
  listContracts(
    page: number,
    count: number,
    from: string,
    to: string,
    orderBy: string,
    order: string,
    filters: any
  ) {
    return this.gridQueriesService.getGridData('contratos/contratos', {
      page,
      count,
      from,
      to,
      orderBy,
      order,
      filters,
    });
  }

  /**
   * Service for Contracts Totals
   * @param from from
   * @param to to
   * @param filters filters
   */
  listContractsTotals(from: string, to: string, filters: any) {
    let params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('desde', from ?? '')
      .set('hasta', to ?? '');
    params = this.http.addFilterParams(filters, params);

    return this.http.get('contratos/contratos-totales', params);
  }

  /**
   * Returns contract details.
   * @param contractNumber String that identifies the contract's info.
   */
  getContractDetails(contractNumber: string) {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());

    return this.http.get(`contratos/contratos/${contractNumber}`, params);
  }

  /**
   * Gets column list for unloads grid based on the active user's cuit
   */
  listContractsColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/contratos', params);
  }
}
