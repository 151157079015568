import {
  Component, Inject, OnDestroy, OnInit
} from '@angular/core';
import { TableStatusEnum } from 'src/app/_shared/components/table-status/table-status.enum';
import { PaymentsService } from 'src/app/_core/services/payments/payments.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { exportPDF } from 'src/app/_shared/helpers/fileHelper';
import { formatGrid, userTooltips, completeSubscription } from '../../helpers/listRole';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileTypeEnum } from '../../helpers/applicationConstants';
import { CheckingAccountsService } from 'src/app/_core/services/checking-accounts/checking-accounts.service';
import { GoogeTagService } from 'src/app/_core/services/google-tag-manager/googe-tag-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pay-order-datails-page',
  templateUrl: './pay-order-datails-page.component.html',
  styleUrls: ['./pay-order-datails-page.component.scss']
})
export class PayOrderDatailsPageComponent implements OnInit, OnDestroy {
  /**
   * Tab Selected.
   */
  selectedTab = 0;

  /**
   * Variable that store pay order proofs.
   */
  dataSource: any;

  /**
   * Variable that store total of pay order proofs.
   */
  dataSourceTotal = 0;

  /**
   * Variable to store the pay order number.
   */
  payOrderNumber: any;

  /**
   * Show spinner during download process.
   */
  showSpinnerRetenciones: boolean;

  /**
   * Show spinner during download process.
   */
  showSpinnerOP: boolean;

  /**
   * Variable that store pay order general info.
   */
  payOrderGeneralInfo: any = {};

  /**
   * Variable that stores Vendedor/es field name.
   */
  labelVendedor = '';

  /**
   * Variable that stores Vendedor/es field value.
   */
  vendedores = '';

  /**
   * Variable that stores attributes for pagination
   */
  tableattr: any = {
    count: 10,
    page: 1
  };

  /**
   * Used for mat-table proofs rows definitions.
   */
  dataColumnsProofs = [
    'tipoComprobante',
    'nroComprobante',
    'nombre',
    'fecha',
    'contrato',
    'importe'
  ];

  /**
   * Used for mat-table instruments rows definitions.
   */
  dataColumnsInstruments = [
    'instrumento',
    'nroCheque',
    'cuenta',
    'banco',
    'nombre',
    'importe'
  ];

  /**
   * Used for mat-table instruments rows definitions.
   */
  dataColumnsRetentions = [
    'fecha',
    'tipoRetencion',
    'nroRetencion',
    'referencia'
  ];

  tableStatus: TableStatusEnum = TableStatusEnum.LOADING;

  /**
   * Used in order to cancel request when component is destroyed.
   */
  ngUnsubscribe = new Subject();

  /**
   * Used to check if this is the first time we're running calling General Info Tab.
   */
  private firstLoad = true;

  private PdfRetencionesFileName = 'Retenciones';

  private PdfOrdenDePagoFileName = 'Orden de pago';

  constructor(
    private dialogRef: MatDialogRef<PayOrderDatailsPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly paymentService: PaymentsService,
    private readonly checkingAccountsService: CheckingAccountsService,
    private readonly googleTagService: GoogeTagService,
    private readonly router: Router,
  ) {
    this.payOrderNumber = data.payOrder;
    this.getOrderGeneralInfo();
  }

  ngOnInit() {
    userTooltips();
  }

  ngOnDestroy() {
    this.completeSubscription();
  }

  /**
   * Applies format to cell
   * @param format format
   * @param cell cell
   */
  transform(format, cell) {
    return formatGrid(format, cell);
  }

  /**
   * Gets Order Info based on selected tab.
   * @param event event
   */
  getPayOrderData(event) {
    this.setDefault();
    switch (event.index) {
      case 0:
        this.getOrderGeneralInfo();
        break;
      case 1:
        this.getOrderProofs();
        break;
      case 2:
        this.getOrderInstruments();
        break;
      case 3:
        this.getOrderRetentions();
        break;
      default:
        break;
    }
  }

  /**
   * Close dialog when user click cancel button
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Performs a back-end request to get the pay order's information.
   */
  getOrderGeneralInfo() {
    if (!this.firstLoad) {
      this.completeSubscription();
      this.ngUnsubscribe = new Subject();
    } else {
      this.firstLoad = false;
    }
    this.tableStatus = TableStatusEnum.LOADING;
    this.paymentService.getOrderGeneralInfo(
      this.payOrderNumber
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.payOrderGeneralInfo = res.pago;
          this.labelVendedor = (res.pago.listaVendedores.length > 1) ? 'Vendedores' : 'Vendedor';
          this.setVendedores(res.pago.listaVendedores);
          this.tableStatus = TableStatusEnum.DONE;
          this.selectedTab = 0;
        },
        (err: any) => {
          console.error(err);
          this.tableStatus = TableStatusEnum.ERROR;
        }
      );
  }

  /**
   * Sets Vendedor/es field based on vendedoresArray content.
   */
  setVendedores(vendedoresArray: Array<string>) {
    switch (vendedoresArray.length) {
      // No 'Vendedores'
      case 0:
        this.vendedores = '---';
        break;
      // One 'Vendedor'
      case 1:
        [this.vendedores] = vendedoresArray;
        break;
      // More than one 'Vendedor'
      default:
        [this.vendedores] = vendedoresArray;
        for (let i = 1; i < vendedoresArray.length; i++) {
          this.vendedores += `, ${vendedoresArray[i]}`;
        }
        break;
    }
  }

  /**
   * Performs a back-end request to get the retentions information.
   */
  getOrderRetentions() {
    this.completeSubscription();
    this.ngUnsubscribe = new Subject();
    this.tableStatus = TableStatusEnum.LOADING;
    this.paymentService.getOrderRetentions(
      this.payOrderNumber
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.dataSource = new MatTableDataSource(res.retenciones);
          this.dataSourceTotal = res.cantidadTotal;
          if (this.dataSourceTotal === 0) {
            this.tableStatus = TableStatusEnum.EMPTY;
          } else {
            this.tableStatus = TableStatusEnum.DONE;
          }
          this.selectedTab = 3;
        },
        (err: any) => {
          console.error(err);
          this.tableStatus = TableStatusEnum.ERROR;
        }
      );
  }

  /**
   * Retenciones PDF. Calls the PDF service to get the download link.
   */
  downloadRetencionesPDF() {
    this.showSpinnerRetenciones = true;
    this.paymentService.downloadRetentionsPDF(
      this.payOrderNumber
    ).subscribe(
      (res: any) => {
        const filename = exportPDF(res, { name: this.PdfRetencionesFileName });
        // Enviar evento exitoso descarga .pdf a Tag Manager.
        this.googleTagService.sendDownloadEvent({
          modulo: this.data.modulo,
          documento: filename,
          extension: '.pdf',
          seccion: this.router.url
        });
        this.showSpinnerRetenciones = false;
      },
      (err: any) => {
        console.error(err);
        this.googleTagService.sendErrorEvent({ accion: 'descarga' }, undefined, {
          modulo: this.data.modulo,
          extension: '.pdf',
          seccion: this.router.url,
          param_1: `OP: ${this.payOrderNumber}`
        });
        this.showSpinnerRetenciones = false;
      }
    );
  }

  /**
   * Downloads Orden de Pago PDF.
   * Based on row data, calls current account or payment service.
   */
  downloadOrdenDePagoPDF() {
    this.showSpinnerOP = true;
    // If element has PDF at Genexus we use checking account service.
    if (this.data.tienePDF) {
      this.checkingAccountsService.downloadComprobantePDF(
        this.data.comprobanteToDownload
      ).subscribe(
        (res: any) => {
          const filename = exportPDF(res, { name: 'Comprobantes' });
          // Enviar evento exitoso descarga .pdf a Tag Manager.
          this.googleTagService.sendDownloadEvent({
            modulo: this.data.modulo,
            documento: filename,
            extension: '.pdf',
            seccion: this.router.url
          });
          this.showSpinnerOP = false;
        },
        (err: any) => {
          console.error(err);
          this.googleTagService.sendErrorEvent({ accion: 'descarga' }, undefined, {
            modulo: this.data.modulo,
            extension: '.pdf',
            seccion: this.router.url,
            documento: this.PdfOrdenDePagoFileName,
            param_1: `OP: ${this.data.comprobanteToDownload.numero}`
          });
          this.showSpinnerOP = false;
        }
      );
    } else {
      // Otherwise, we use payment service.
      this.paymentService.downloadOrdenDePagoPDF(
        this.payOrderNumber
      ).subscribe(
        (res: any) => {
          const fileData = {
            name: this.PdfOrdenDePagoFileName,
            documentType: FileTypeEnum.ORDEN_DE_PAGO,
            ordenDePago: this.payOrderNumber
          };
          const filename = exportPDF(res, fileData);
          // Enviar evento exitoso descarga .pdf a Tag Manager.
          this.googleTagService.sendDownloadEvent({
            modulo: this.data.modulo,
            documento: filename,
            extension: '.pdf',
            seccion: this.router.url
          });
          this.showSpinnerOP = false;
        },
        (err: any) => {
          console.error(err);
          this.googleTagService.sendErrorEvent({ accion: 'descarga' }, undefined, {
            modulo: this.data.modulo,
            extension: '.pdf',
            seccion: this.router.url,
            param_1: `OP: ${this.payOrderNumber}`
          });
          this.showSpinnerOP = false;
        }
      );
    }
  }

  /**
   * Performs a back-end request to get the pay order's proofs.
   */
  getOrderProofs() {
    this.completeSubscription();
    this.ngUnsubscribe = new Subject();
    this.tableStatus = TableStatusEnum.LOADING;
    this.paymentService.getOrderProofs(
      this.payOrderNumber,
      this.tableattr.page,
      this.tableattr.count
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.dataSource = new MatTableDataSource(res.comprobantes);
          this.dataSourceTotal = res.cantidadTotal;
          if (this.dataSourceTotal === 0) {
            this.tableStatus = TableStatusEnum.EMPTY;
          } else {
            this.tableStatus = TableStatusEnum.DONE;
          }
          this.selectedTab = 1;
        },
        (err: any) => {
          console.error(err);
          this.tableStatus = TableStatusEnum.ERROR;
        }
      );
  }

  /**
   * Performs a back-end request to get the pay order's proofs.
   */
  getOrderInstruments() {
    this.completeSubscription();
    this.ngUnsubscribe = new Subject();
    this.tableStatus = TableStatusEnum.LOADING;
    this.paymentService.getOrderInstruments(
      this.payOrderNumber,
      this.tableattr.page,
      this.tableattr.count
    ).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.dataSource = new MatTableDataSource(res.instrumentos);
          this.dataSourceTotal = res.cantidadTotal;
          if (this.dataSourceTotal === 0) {
            this.tableStatus = TableStatusEnum.EMPTY;
          } else {
            this.tableStatus = TableStatusEnum.DONE;
          }
          this.selectedTab = 2;
        },
        (err: any) => {
          console.error(err);
          this.tableStatus = TableStatusEnum.ERROR;
        }
      );
  }

  /**
   * Handle Paginator event
   * @param e event
   */
  handlePage(e: any) {
    this.tableattr.page = e.pageIndex + 1;
    this.tableattr.count = e.pageSize;
    switch (this.selectedTab) {
      case 1:
        this.getOrderProofs();
        break;
      case 2:
        this.getOrderInstruments();
        break;
      default:
        break;
    }
  }

  /**
   * Unsubscribes all pending requests.
   */
  completeSubscription() {
    completeSubscription(this.ngUnsubscribe);
  }

  /**
   * Sets default values.
   */
  private setDefault() {
    this.tableStatus = TableStatusEnum.LOADING;
    this.tableattr = {
      count: 10,
      page: 1
    };
  }
}

